import React from "react";

import { WEB_FLOW_ROUTES } from "constants/index";
import { useFormattedMessage, useUtils } from "hooks";
import Link from "components/NavLink";

const tcMessages = {
    registerNewTermsFirstPatch: "by_registering_for_application",
    registerNewTermsSecondPatch: "and_the",
    registerNewTermsThirdPatch: "the",
    registerNewTermsPrivacyLink: "privacy_policy",
    registerNewTermsTransparencyDocumentLink: "transparency_document",
};

const NewFormTermsCondition = ({ className = "" }) => {
    const { formatMessage } = useFormattedMessage();
    const { URLDataPrivacy, URLTransparency, LegalName: tenantLegalName, TechnicalName: currentTenant } = useUtils().tenant;
    const isTenantFinexity = useUtils().isTenantFinexity;

    return (
        <div className={`forms-terms-n-condition ${className}`}>
            <div className={"mb-3 line-height-normal"}>
                <span className="text-12 password-info">
                    {formatMessage(tcMessages.registerNewTermsFirstPatch)}{" "}
                    <Link
                        isExternal
                        className="primary-color d-inline-block"
                        href={isTenantFinexity ? WEB_FLOW_ROUTES.PRIVACY_POLICY : URLDataPrivacy}
                    >
                        {formatMessage(tcMessages.registerNewTermsPrivacyLink)}
                    </Link>{" "}
                    {formatMessage(tcMessages.registerNewTermsSecondPatch)}{" "}
                    <Link isExternal className="primary-color d-inline-block" href={URLTransparency}>
                        {formatMessage(tcMessages.registerNewTermsTransparencyDocumentLink)}
                    </Link>{" "}
                    {formatMessage(tcMessages.registerNewTermsThirdPatch)}{" "}
                    <Link
                        isExternal
                        className="primary-color d-inline-block"
                        href={`/docs/tenant/${currentTenant}/termsandconditions/marketplace.pdf`}
                    >
                        {tenantLegalName} AGB
                    </Link>
                    {"."}
                </span>
            </div>
        </div>
    );
};

export default NewFormTermsCondition;
